.AppWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.App {
  top: 50%;
  left: 50%;
  position: absolute;
  transform-origin: center;
}
.game-wrap {
  background: #000;
  position: relative;
}
.game-wrap .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.game-wrap .item {
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.15);
}
.game-wrap .item.inactive {
  background: #000;
  border: 1px solid #000;
}
.game-wrap .item button {
  margin: 10px;
  padding: 0;
}
.game-wrap .item button.btn-active.active {
  background: #f4f4f4;
}
.game-wrap .item button.btn-target {
  background: transparent;
  border: 0;
  text: #000;
}
.hud {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #fff;
}
.hud button {
  margin: 0;
}
dialog article > footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: .5rem;
  background: #333;
}
.panel > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.panel button {
  height: 35px;
  line-height: 35px;
  font-size: .5rem;
  margin-bottom: 0;
  display: inline-block !important;
  padding: 0 .5rem !important;
  width: auto !important;
  border: 0;
}
.panel button.icon {
  width: 35px;
  min-width: 35px;
  text-indent: -100em;
  overflow: hidden;
  background-position: center;
  background-size: 75%;
  padding: 0;
  margin: 0 3px;
}
